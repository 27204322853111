<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" 
      style="min-height: 265px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ this.messagetype.title_jp }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <a @click="navigate('/admin/news')" style="cursor: pointer"
                >ダッシュボード</a
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Admin message',
                  params: { type: message_type },
                }"
                >{{ this.messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li><span>新規投稿</span></li>
          </ul>
        </nav>
      </div>

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">確認</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span
                            >入力途中の内容は削除されますがよろしいですか？</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          value="キャンセル"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          キャンセル
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          value="アーカイブ"
                          title="削除"
                          dusk="save"
                        >
                          実行
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
          <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
        </div>
        <!--modal_inner-->
      </div>
      <!--modal-->
      <!-- Vee-validate form field -->
      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="register"
        class="createForm"
      >
        <div class="command_wrap command_bottom">
          <div class="leftarea btn_wrap">
            <div class="return_link">
              <router-link
              :to="{ name: 'Admin message', params: { type: message_type } }"
              >
                <span class="icon"><i class="fas fa-chevron-left"></i></span
                >{{ this.messagetype.title_jp }}一覧
              </router-link>
            </div>
            <div></div>
          </div>
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button type="submit" dusk="com_save_button">保存</button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>
        <!--command_wrap-->

        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">
                新規{{ this.messagetype.title_jp }}
              </h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr v-if="messagecolumn['title']">
                        <th class="wid_200px">{{ messagecolumn["title"] }}</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="title"
                                  type="text"
                                  class="form-control"
                                  placeholder="タイトルを入力してください"
                                  v-model="cretemessage.title"
                                  id="title"
                                  :rules="isRequired"
                                />
                                <ErrorMessage class="each_msg" name="title" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="messagecolumn['content']">
                        <th class="wid_200px">
                          {{ messagecolumn["content"] }}
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="cretemessage.content"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="cretemessage.content"
                                  id="content"
                                  :rules="isRequired"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr v-if="messagecolumn['group']">
                        <th class="wid_200px">{{ messagecolumn["group"] }}</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="group"
                                  type="text"
                                  readonly
                                  class="form-control"
                                  v-model="cretemessage.group"
                                  id="group"
                                />
                                <ErrorMessage class="each_msg" name="group" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          messagecolumn['tags'] &&
                          message_type != 'column' &&
                          message_type != 'photolog'
                        "
                      >
                        <th class="wid_200px">{{ messagecolumn["tags"] }}</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="select_wrap multi">
                                  <Field
                                    required=""
                                    name="tags"
                                    as="select"
                                    v-model="cretemessage.tags"
                                    :rules="isRequiredArr"
                                    multiple
                                  >
                                    <option
                                      v-for="taglist in this.taglists.slice()"
                                      v-bind:value="taglist.id"
                                      :key="taglist.id"
                                    >
                                      {{ taglist.name }}
                                    </option>
                                  </Field>
                                  <ErrorMessage class="each_msg" name="group" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="messagecolumn['target_ids']">
                        <th class="wid_200px">
                          {{ messagecolumn["target_ids"] }}
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="select_wrap multi">
                                  <div class="searchInput">
                                    <input
                                      type="text"
                                      @keyup="searchGroupUser($event)"
                                      placeholder="ユーザーを絞り込み"
                                    />
                                  </div>

                                  <Field
                                    required=""
                                    name="target_ids"
                                    as="select"
                                    v-model="cretemessage.target_ids"
                                    @change="setAthesekiUser($event)"
                                    :rules="isRequiredArr"
                                    multiple
                                  >
                                    <option
                                      v-for="user in this.groupUsers.slice()"
                                      v-bind:value="user.id"
                                      :key="user.id"
                                    >
                                      {{ user.last_name }} {{ user.first_name }}
                                      {{
                                        user.role ? " (" + user.role + ")" : ""
                                      }}
                                    </option>
                                  </Field>
                                </div>
                                <ErrorMessage
                                  class="each_msg"
                                  name="target_ids"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        id="attached_area"
                        v-if="messagecolumn['attached_file']"
                      >
                        <th class="wid_200px">
                          {{ messagecolumn["attached_file"] }}
                        </th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">
                                  アップロードファイル（PDF形式）
                                </p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div
                            class="row_form_wrap m_b20"
                            id="itembox_doc"
                          ></div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap right">
              <h2 class="ttl_2">公開情報</h2>
              <div class="form_outwrap">
                <div
                  v-if="messagecolumn['status']"
                  class="wrap wid_100per p_r0"
                >
                  <div>
                    <p class="ttl_3">{{ messagecolumn["status"] }}</p>
                  </div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        class="form-control"
                        v-model="cretemessage.status"
                        :rules="isRequired"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0" style="display: none">
                  <div><p class="ttl_3">カテゴリ</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        name="type"
                        as="select"
                        class="form-control"
                        v-model="cretemessage.type"
                      >
                        <option value="news">お知らせ</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="type" />
                  </div>
                </div>
                <!--wrap-->
                <div v-if="messagecolumn['published_at']" class="wrap p_r0">
                  <p class="ttl_3">{{ messagecolumn["published_at"] }}</p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="cretemessage.published_at"
                    />
                    <ErrorMessage class="each_msg" name="published_at" />
                    <!--datepickbox-->
                  </div>
                  <!--in_wrap-->
                </div>
                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>
            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
    <template id="item_doc_Template">
      <div class="row_wrap new_item sort_item attached_wrap">
        <div class="col">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <input
                  type="hidden"
                  name="jdg_val[]"
                  class="fileHiddenName"
                  value="new"
                />
                <input
                  name="rel_doc_title[]"
                  type="text"
                  class="fileName uploadFileName"
                  required
                  placeholder="例：1月の営業スケジュールについて"
                  value=""
                />
              </div>
              <div class="fileDisplayName"></div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="col item_list">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <div class="filegroup">
                  <input
                    type="file"
                    name="rel_doc[]"
                    class="input-file"
                    style="display: none"
                    accept=".pdf"
                    required
                  />
                  <label class="filelabel fileup-btn">ファイルを選択</label>
                </div>
              </div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="icon col wid_50px t_c">
          <div class="remove_wrap">
            <a
              href=""
              class="item_remove_btn remove_btn tooltip tip-left delete_task_link"
              title="削除"
              ><i class="fas fa-times"></i
            ></a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import message from "@/apis/Message";
import user from "@/apis/User";
import SDGs from "@/apis/SDGs";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import Groups from "../../../apis/Groups";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      taglists: "",
      messages: [],
      cretemessage: {
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: this.$route.params.type,
        content_type: "HTML",
        thanks_type: [],
        public: "非公開",
        attached_file_name: []
      },
      attached_file: [],
      loader: false,
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      editor: ClassicEditor,
      editorConfig: {},
      editorValidation: true,
      processing: false,
      searchGroupId: this.$User.group.id,
      groupUsers: [],
      pageParam: {
        pageNo: 1,
        perPageCount: 25,
        messageType: this.$route.params.type,
        searchYear: new Date().getFullYear(),
        searchMonth: '10~12月',
        mySdgsSummary: [],
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.messagecolumn = MessageColumn[this.message_type];

    if (this.messagecolumn["admin_create"] == undefined) {
      this.$router.push({ name: "Front 404" });
    }

    Groups.getAllGroupUsers().then(
      (response) => {
        this.groupUsers = response.data.data;
        this.userlists = response.data.data;
      }
    );

    this.messagetype = MessageType[this.message_type];
    if (this.messagecolumn["target_ids"]) {
      this.cretemessage.target_type = "users";
    }
    if (this.messagecolumn["group"] && this.$User.group != null) {
      this.cretemessage.group = this.$User.group.name;
    }
    this.getUserLists();
    if (this.messagecolumn["tag_list"]) {
      this.getTagLists(this.messagecolumn["tag_list"]);
    }
  },
  mounted() {
    let vueThis = this;
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    $(function () {
      $(".target_user_select").select2({
        width: "260px",
        language: {
          noResults: function () {
            return "登録されていません";
          },
        },
        placeholder: "宛先のユーザーを選択",
        allowClear: true,
        multiple: true,
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      var $filesSelectName = $el
        .closest(".attached_wrap,.new_item")
        .find(".file_name");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        if (e.target.files[0].name) {
          $fileName.val(e.target.files[0].name);
        } else {
          var today = new Date();
          $fileName.val(today + e.target.files[0].type);
          // $($fileDisplayName).html("資料名を入力してください");
          // $($fileDisplayName).addClass('each_msg')
        }
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el
        .closest(".attached_wrap,.new_item")
        .find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#item_doc_Template").html();
      $itembox.after(htmlString);
    });

    $(document).on("click", ".delete_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
  },
  methods: {
    changeYear: function (event) {

      if (event.target.value != "") {
        let currentMonth = this.pageParam.searchMonth;
        if (currentMonth == 10 || currentMonth == 11 || currentMonth == 12) {
          this.pageParam.searchYear = event.target.value - 1;
        } else {
          this.pageParam.searchYear = event.target.value;
        }
      } else {
        this.pageParam.searchYear = "";
      }
    },
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    isRequired(value, name) {
      if (value) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    isRequiredArr(value, name) {
      if (value.length != 0) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    enable: function () {
      this.processing = false;
    },
    register() {
      this.processing = true;
      setTimeout(this.enable, 3000);
      if (!this.messagecolumn["title"]) {
        this.cretemessage.title = this.messagecolumn["title_str"];
      }
      if (
        this.messagecolumn["published_at"] &&
        this.cretemessage.published_at
      ) {
        this.cretemessage.published_at = this.cretemessage.published_at.replace(
          "T",
          " "
        );
        this.cretemessage.published_at += ":00";
      }
      if (this.messagecolumn["attached_file"]) {
        console.log($("#attached_area .attached_wrap"));
        for (var i = 0; i < $("#attached_area .attached_wrap").length; i++) {
          let $el = $(".attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");

          if (!($fileName.val() == "" && $fileData[0].files.length == 0)) {
            if ($fileName.val() == "") {
              alert("資料名を入力してください");
              this.attached_file = [];
              return;
            }
            if ($fileData[0].files.length == 0) {
              alert("ファイルを選択してください");
              this.attached_file = [];
              return;
            }
            if (file && permit_type.indexOf(file.type) == -1) {
              alert("アップロードできるファイルの形式はpdfです");
              this.attached_file = [];
              return;
            }

            let fileObject = new FormData();

            fileObject.append("key", "attached_file");
            fileObject.append("display_name", $fileName.val());
            fileObject.append("uploadfile", $fileData[0].files[0]);

            this.attached_file.push(fileObject);

            var file = $fileData[0].files[0];
            var permit_type = ["application/pdf"];
          }
        }
      }

      message
        .register(this.cretemessage)
        .then(
          (response) => {
            this.postMessageMeta(
              "from_admin",
              "管理者",
              "管理画面からの投稿",
              response.data.data.id
            );
            if (this.cretemessage.sdgs_type) {
              this.postMessageMeta(
                "sdgs_type",
                this.cretemessage.sdgs_type,
                "SDGsタイプ",
                response.data.data.id
              );
            }
            if (this.cretemessage.group) {
              this.postMessageMeta(
                "group",
                this.cretemessage.group,
                "部門",
                response.data.data.id
              );
            }
            if (this.cretemessage.public) {
              this.postMessageMeta(
                "public",
                this.cretemessage.public,
                "一般公開設定",
                response.data.data.id
              );
            }
            if (this.cretemessage.thanks_type) {
              this.cretemessage.thanks_type.forEach((thanks_type) => {
                this.postMessageMeta(
                  "thanks_type",
                  thanks_type,
                  "サンクスのタイプ",
                  response.data.data.id
                );
              });
            }
            if (this.cretemessage.photolog) {
              this.postMessagefile(
                "photolog",
                "photo_log",
                response.data.data.id
              );
            }
            /*
			if(this.cretemessage.attached_file){
				this.postMessageAttachedfile('attached_file',response.data.data.id);
			}
			*/
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            this.$router.push({
              name: "Admin message",
              params: { type: this.message_type },
            });
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },

    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        await message
          .register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },

    getUserLists() {
      user
        .list()
        .then((response) => {
          if (response != null) {
            this.userlists = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getTagLists(tag_array) {
      message
        .tag_list(tag_array)
        .then((response) => {
          if (response != null) {
            this.taglists = response.data.data;
            if (
              this.message_type == "photolog" ||
              this.message_type == "column"
            ) {
              var target = this.taglists.filter((e) => e["name"] === "業務");
              this.cretemessage.tags = [target[0]["id"]];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    postMessageMeta(key, value, description, id) {
      var post_json = {
        key: key,
        value: value,
        description: description,
      };
      message
        .register_meta(post_json, id)
        .then(
          (response) => {
            console.log("meta");
            console.log(response);
          },
          (error) => {
            console.log("metaerror");
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("metaerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    postMessagefile(key, display_name, id) {
      let fileObject = new FormData();

      fileObject.append("key", key);
      fileObject.append("display_name", display_name);
      fileObject.append("uploadfile", this.cretemessage.photolog[0]);

      console.log(fileObject);
      message
        .register_file(fileObject, id)
        .then(
          (response) => {
            console.log("file");
            console.log(response);
          },
          (error) => {
            console.log("fileerror");
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("fileerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    searchGroupUser(event) {
      Groups.getSearchGroupUsers(
        null,
        event.target.value,
        this.message_type
      ).then((response) => {
        this.groupUsers = response.data.data;
        this.userlists = response.data.data;
      });
    },
    setAthesekiUser(e) {
      if (!this.cretemessage.target_ids.includes(e.target.value)) {
        this.cretemessage.target_ids.push(e.target.value);
      }
      console.log(this.cretemessage.target_ids);
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
